import './NameAMT.scss'

function NameAMT() {
    return (
        <>
            <svg
                className='svg'
                xmlns="http://www.w3.org/2000/svg"
                width="900"
                zoomAndPan="magnify"
                viewBox="0 0 675 74.999997"
                height="100"
                preserveAspectRatio="xMidYMid meet"
                version="1.0">
                <defs><g /></defs><g fill="#eab052" fill-opacity="1">
                    <g transform="translate(26.843803, 58.999997)"><g>
                        <path className="path otherPath" d="M 19 -40.746094 C 24.246094 -40.746094 28.445312 -38.644531 30.148438 -37.644531 L 27.445312 -32.347656 C 25.847656 -33.246094 22.898438 -34.695312 19.648438 -34.695312 C 16.046875 -34.695312 13.5 -32.847656 13.5 -29.746094 C 13.5 -26.148438 18.5 -24.449219 22.847656 -22.597656 C 27.296875 -20.746094 31.746094 -17.097656 31.746094 -12.546875 C 31.746094 -3.601562 25.148438 -0.5 17.449219 -0.5 C 10.347656 -0.5 5.398438 -3.601562 3.550781 -4.949219 L 7 -10.699219 C 8.898438 -9.347656 12.847656 -6.699219 16.75 -6.699219 C 18.546875 -6.699219 23.949219 -6.800781 23.949219 -12 C 23.949219 -16.199219 18.347656 -17.699219 13.949219 -19.898438 C 9.25 -22.199219 6.800781 -25.699219 6.800781 -29.398438 C 6.800781 -36.246094 12.847656 -40.746094 19 -40.746094 Z M 19 -43.246094 C 11.796875 -43.246094 4.300781 -37.894531 4.300781 -29.398438 C 4.300781 -24.449219 7.597656 -20.246094 12.796875 -17.648438 C 18.199219 -14.949219 21.449219 -13.75 21.449219 -12 C 21.449219 -9.449219 19 -9.199219 16.75 -9.199219 C 12.796875 -9.199219 7.449219 -13.546875 7.449219 -13.546875 C 6.398438 -14.398438 5.75 -13.546875 5.550781 -13.199219 L 0.851562 -5.300781 C 0.550781 -4.847656 0.648438 -4.148438 1.050781 -3.75 C 1.199219 -3.648438 7.300781 2 17.449219 2 C 25.796875 2 34.246094 -1.75 34.246094 -12.546875 C 34.246094 -18.75 28.546875 -22.898438 23.796875 -24.949219 C 18.75 -27.097656 16 -28.695312 16 -29.746094 C 16 -31.097656 16.796875 -32.195312 19.648438 -32.195312 C 23.296875 -32.195312 27.246094 -29.546875 27.246094 -29.546875 C 28.195312 -28.898438 28.847656 -29.546875 29.097656 -29.996094 L 32.898438 -37.496094 C 33.148438 -37.996094 32.996094 -38.695312 32.496094 -39.097656 C 32.398438 -39.144531 26.898438 -43.246094 19 -43.246094 Z M 19 -43.246094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(65.43829, 58.999997)"><g>
                        <path className="path otherPath" d="M 25.449219 -39.996094 L 25.449219 -34.246094 L 12.5 -34.246094 C 11.699219 -34.246094 11.25 -33.496094 11.25 -32.996094 L 11.25 -24.796875 C 11.25 -23.996094 11.949219 -23.546875 12.5 -23.546875 L 24.847656 -23.546875 L 24.847656 -17.796875 L 12.5 -17.796875 C 11.699219 -17.796875 11.25 -17.046875 11.25 -16.546875 L 11.25 -8.25 C 11.25 -7.449219 11.949219 -7 12.5 -7 L 25.449219 -7 L 25.449219 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 Z M 27.945312 -41.246094 C 27.945312 -41.746094 27.445312 -42.496094 26.699219 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 26.699219 1.25 C 27.199219 1.25 27.945312 0.800781 27.945312 0 L 27.945312 -8.25 C 27.945312 -8.75 27.445312 -9.5 26.699219 -9.5 L 13.75 -9.5 L 13.75 -15.296875 L 26.097656 -15.296875 C 26.597656 -15.296875 27.347656 -15.75 27.347656 -16.546875 L 27.347656 -24.796875 C 27.347656 -25.296875 26.847656 -26.046875 26.097656 -26.046875 L 13.75 -26.046875 L 13.75 -31.746094 L 26.699219 -31.746094 C 27.199219 -31.746094 27.945312 -32.195312 27.945312 -32.996094 Z M 27.945312 -41.246094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(97.88373, 58.999997)"><g>
                        <path className="path otherPath" d="M 26.449219 -39.996094 L 42.046875 -1.25 L 34.695312 -1.25 L 31.945312 -8.648438 C 31.746094 -9.148438 31.296875 -9.5 30.796875 -9.5 L 15.097656 -9.5 C 14.546875 -9.5 14.097656 -9.148438 13.898438 -8.648438 L 11.148438 -1.25 L 3.800781 -1.25 L 19.398438 -39.996094 Z M 28.445312 -41.695312 C 28.246094 -42.144531 27.796875 -42.496094 27.296875 -42.496094 L 18.597656 -42.496094 C 18.097656 -42.496094 17.597656 -42.144531 17.398438 -41.695312 L 0.75 -0.449219 C 0 1.449219 1.949219 1.25 1.949219 1.25 L 12.046875 1.25 C 12.546875 1.25 13 0.949219 13.199219 0.449219 L 15.949219 -7 L 29.898438 -7 L 32.648438 0.449219 C 32.847656 0.949219 33.296875 1.25 33.847656 1.25 L 43.945312 1.25 C 45.945312 1.25 45.09375 -0.449219 45.09375 -0.449219 Z M 28.746094 -16.949219 L 24.046875 -28.945312 C 24.046875 -28.945312 22.546875 -31.195312 21.699219 -28.898438 L 17.097656 -16.898438 C 17.097656 -16.898438 16.296875 -15.25 18.296875 -15.25 L 27.597656 -15.25 C 27.597656 -15.25 29.445312 -15.046875 28.746094 -16.949219 Z M 25.746094 -17.75 L 20.097656 -17.75 L 22.898438 -24.996094 Z M 25.746094 -17.75 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(146.776628, 58.999997)"><g>
                        <path className="path otherPath" d="M 27.148438 -1.5 L 23.898438 -1.5 L 13.699219 -26.597656 C 13.699219 -26.597656 11.300781 -27.648438 11.300781 -26.148438 L 11.199219 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 L 15.5 -39.996094 L 24.347656 -17 C 24.347656 -17 25.796875 -14.699219 26.699219 -17 L 35.546875 -39.996094 L 46.746094 -39.996094 L 46.746094 -1.25 L 39.847656 -1.25 L 39.796875 -26.148438 C 39.796875 -26.148438 37.945312 -27.996094 37.347656 -26.597656 Z M 27.996094 1 C 28.445312 1 28.945312 0.699219 29.148438 0.25 L 37.296875 -19.796875 L 37.394531 0.0507812 C 37.394531 0.648438 37.894531 1.25 38.644531 1.25 L 47.996094 1.25 C 48.496094 1.25 49.246094 0.800781 49.246094 0 L 49.246094 -41.246094 C 49.246094 -41.746094 48.746094 -42.496094 47.996094 -42.496094 L 34.695312 -42.496094 C 34.195312 -42.496094 33.695312 -42.144531 33.496094 -41.644531 L 25.546875 -20.898438 L 17.546875 -41.644531 C 17.347656 -42.144531 16.898438 -42.496094 16.398438 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 12.449219 1.25 C 13.046875 1.25 13.648438 0.75 13.699219 0.0507812 L 13.75 -19.796875 L 21.898438 0.25 C 22.097656 0.699219 22.546875 1 23.097656 1 Z M 27.996094 1 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(200.968701, 58.999997)"><g>
                        <path className="path otherPath" d="M 33.847656 -39.996094 L 33.847656 -14.648438 C 33.847656 -5.050781 28.945312 -0.5 19 -0.5 C 9 -0.5 4.148438 -5.050781 4.148438 -14.648438 L 4.148438 -39.996094 L 11.050781 -39.996094 L 11.050781 -17.398438 C 11.050781 -10.148438 13.648438 -7.25 19 -7.25 C 24.296875 -7.25 26.949219 -10.148438 26.949219 -17.398438 L 26.949219 -39.996094 Z M 36.347656 -41.246094 C 36.347656 -41.746094 35.847656 -42.496094 35.097656 -42.496094 L 25.699219 -42.496094 C 25.148438 -42.496094 24.449219 -41.996094 24.449219 -41.246094 L 24.449219 -17.398438 C 24.449219 -10.800781 22.847656 -9.75 19 -9.75 C 15.097656 -9.75 13.546875 -10.800781 13.546875 -17.398438 L 13.546875 -41.246094 C 13.546875 -41.746094 13.046875 -42.496094 12.296875 -42.496094 L 2.898438 -42.496094 C 2.351562 -42.496094 1.648438 -41.996094 1.648438 -41.246094 L 1.648438 -14.648438 C 1.648438 -3.800781 7.949219 2 19 2 C 29.996094 2 36.347656 -3.800781 36.347656 -14.648438 Z M 36.347656 -41.246094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(242.112783, 58.999997)"><g>
                        <path className="path otherPath" d="M 19 -40.746094 C 24.246094 -40.746094 28.445312 -38.644531 30.148438 -37.644531 L 27.445312 -32.347656 C 25.847656 -33.246094 22.898438 -34.695312 19.648438 -34.695312 C 16.046875 -34.695312 13.5 -32.847656 13.5 -29.746094 C 13.5 -26.148438 18.5 -24.449219 22.847656 -22.597656 C 27.296875 -20.746094 31.746094 -17.097656 31.746094 -12.546875 C 31.746094 -3.601562 25.148438 -0.5 17.449219 -0.5 C 10.347656 -0.5 5.398438 -3.601562 3.550781 -4.949219 L 7 -10.699219 C 8.898438 -9.347656 12.847656 -6.699219 16.75 -6.699219 C 18.546875 -6.699219 23.949219 -6.800781 23.949219 -12 C 23.949219 -16.199219 18.347656 -17.699219 13.949219 -19.898438 C 9.25 -22.199219 6.800781 -25.699219 6.800781 -29.398438 C 6.800781 -36.246094 12.847656 -40.746094 19 -40.746094 Z M 19 -43.246094 C 11.796875 -43.246094 4.300781 -37.894531 4.300781 -29.398438 C 4.300781 -24.449219 7.597656 -20.246094 12.796875 -17.648438 C 18.199219 -14.949219 21.449219 -13.75 21.449219 -12 C 21.449219 -9.449219 19 -9.199219 16.75 -9.199219 C 12.796875 -9.199219 7.449219 -13.546875 7.449219 -13.546875 C 6.398438 -14.398438 5.75 -13.546875 5.550781 -13.199219 L 0.851562 -5.300781 C 0.550781 -4.847656 0.648438 -4.148438 1.050781 -3.75 C 1.199219 -3.648438 7.300781 2 17.449219 2 C 25.796875 2 34.246094 -1.75 34.246094 -12.546875 C 34.246094 -18.75 28.546875 -22.898438 23.796875 -24.949219 C 18.75 -27.097656 16 -28.695312 16 -29.746094 C 16 -31.097656 16.796875 -32.195312 19.648438 -32.195312 C 23.296875 -32.195312 27.246094 -29.546875 27.246094 -29.546875 C 28.195312 -28.898438 28.847656 -29.546875 29.097656 -29.996094 L 32.898438 -37.496094 C 33.148438 -37.996094 32.996094 -38.695312 32.496094 -39.097656 C 32.398438 -39.144531 26.898438 -43.246094 19 -43.246094 Z M 19 -43.246094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(301.203156, 58.999997)"><g>
                        <path className="path otherPath" d="M 11.25 -25.597656 L 11.25 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 L 11.796875 -39.996094 L 27.796875 -14.949219 C 28.046875 -14.597656 28.445312 -14.398438 28.898438 -14.398438 L 28.996094 -14.398438 C 29.746094 -14.398438 30.246094 -15.097656 30.246094 -15.648438 L 30.246094 -39.996094 L 37.144531 -39.996094 L 37.144531 -1.25 L 29.648438 -1.25 L 13.648438 -26.246094 C 13.398438 -26.597656 13 -26.847656 12.597656 -26.847656 L 12.5 -26.847656 C 11.699219 -26.847656 11.25 -26.097656 11.25 -25.597656 Z M 13.75 -21.449219 L 27.898438 0.699219 C 28.148438 1.050781 28.546875 1.25 28.996094 1.25 L 38.394531 1.25 C 38.894531 1.25 39.644531 0.800781 39.644531 0 L 39.644531 -41.246094 C 39.644531 -41.746094 39.144531 -42.496094 38.394531 -42.496094 L 28.996094 -42.496094 C 28.445312 -42.496094 27.746094 -41.996094 27.746094 -41.246094 L 27.746094 -19.746094 L 13.546875 -41.894531 C 13.296875 -42.246094 12.898438 -42.496094 12.5 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 12.5 1.25 C 13 1.25 13.75 0.800781 13.75 0 Z M 13.75 -21.449219 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(345.796715, 58.999997)"><g>
                        <path className="path otherPath" d="M 26.449219 -39.996094 L 42.046875 -1.25 L 34.695312 -1.25 L 31.945312 -8.648438 C 31.746094 -9.148438 31.296875 -9.5 30.796875 -9.5 L 15.097656 -9.5 C 14.546875 -9.5 14.097656 -9.148438 13.898438 -8.648438 L 11.148438 -1.25 L 3.800781 -1.25 L 19.398438 -39.996094 Z M 28.445312 -41.695312 C 28.246094 -42.144531 27.796875 -42.496094 27.296875 -42.496094 L 18.597656 -42.496094 C 18.097656 -42.496094 17.597656 -42.144531 17.398438 -41.695312 L 0.75 -0.449219 C 0 1.449219 1.949219 1.25 1.949219 1.25 L 12.046875 1.25 C 12.546875 1.25 13 0.949219 13.199219 0.449219 L 15.949219 -7 L 29.898438 -7 L 32.648438 0.449219 C 32.847656 0.949219 33.296875 1.25 33.847656 1.25 L 43.945312 1.25 C 45.945312 1.25 45.09375 -0.449219 45.09375 -0.449219 Z M 28.746094 -16.949219 L 24.046875 -28.945312 C 24.046875 -28.945312 22.546875 -31.195312 21.699219 -28.898438 L 17.097656 -16.898438 C 17.097656 -16.898438 16.296875 -15.25 18.296875 -15.25 L 27.597656 -15.25 C 27.597656 -15.25 29.445312 -15.046875 28.746094 -16.949219 Z M 25.746094 -17.75 L 20.097656 -17.75 L 22.898438 -24.996094 Z M 25.746094 -17.75 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(394.689613, 58.999997)"><g>
                        <path className="path otherPath" d="M 26.449219 -39.996094 L 42.046875 -1.25 L 34.695312 -1.25 L 31.945312 -8.648438 C 31.746094 -9.148438 31.296875 -9.5 30.796875 -9.5 L 15.097656 -9.5 C 14.546875 -9.5 14.097656 -9.148438 13.898438 -8.648438 L 11.148438 -1.25 L 3.800781 -1.25 L 19.398438 -39.996094 Z M 28.445312 -41.695312 C 28.246094 -42.144531 27.796875 -42.496094 27.296875 -42.496094 L 18.597656 -42.496094 C 18.097656 -42.496094 17.597656 -42.144531 17.398438 -41.695312 L 0.75 -0.449219 C 0 1.449219 1.949219 1.25 1.949219 1.25 L 12.046875 1.25 C 12.546875 1.25 13 0.949219 13.199219 0.449219 L 15.949219 -7 L 29.898438 -7 L 32.648438 0.449219 C 32.847656 0.949219 33.296875 1.25 33.847656 1.25 L 43.945312 1.25 C 45.945312 1.25 45.09375 -0.449219 45.09375 -0.449219 Z M 28.746094 -16.949219 L 24.046875 -28.945312 C 24.046875 -28.945312 22.546875 -31.195312 21.699219 -28.898438 L 17.097656 -16.898438 C 17.097656 -16.898438 16.296875 -15.25 18.296875 -15.25 L 27.597656 -15.25 C 27.597656 -15.25 29.445312 -15.046875 28.746094 -16.949219 Z M 25.746094 -17.75 L 20.097656 -17.75 L 22.898438 -24.996094 Z M 25.746094 -17.75 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(443.582511, 58.999997)"><g>
                        <path className="path otherPath" d="M 4.097656 -39.996094 L 12.398438 -39.996094 L 20.648438 -27.347656 C 20.648438 -27.347656 21.546875 -25.546875 22.699219 -27.347656 L 30.945312 -39.996094 L 39.246094 -39.996094 L 25.347656 -19.148438 C 25.199219 -18.949219 25.148438 -18.699219 25.148438 -18.5 L 25.148438 -1.25 L 18.25 -1.25 L 18.25 -18.5 C 18.25 -18.699219 18.148438 -19 18 -19.148438 Z M 1.800781 -42.496094 C -0.648438 -42.496094 0.75 -40.546875 0.75 -40.546875 L 15.75 -18.097656 L 15.75 0 C 15.75 0.550781 16.199219 1.25 17 1.25 L 26.398438 1.25 C 26.898438 1.25 27.648438 0.800781 27.648438 0 L 27.648438 -18.097656 L 42.597656 -40.546875 C 43.945312 -42.546875 41.597656 -42.496094 41.597656 -42.496094 L 30.296875 -42.496094 C 29.898438 -42.496094 29.445312 -42.246094 29.246094 -41.894531 L 21.699219 -30.296875 L 14.097656 -41.894531 C 13.898438 -42.246094 13.5 -42.496094 13.097656 -42.496094 Z M 1.800781 -42.496094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(490.075778, 58.999997)"><g>
                        <path className="path otherPath" d="M 37.847656 -1.25 L 28.246094 -1.25 L 13.398438 -18.296875 C 13.398438 -18.296875 11.25 -19.898438 11.25 -17.5 L 11.25 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 L 11.25 -39.996094 L 11.25 -24.398438 C 11.25 -24.398438 11.949219 -21.796875 13.449219 -23.546875 L 27.199219 -39.996094 L 35.296875 -39.996094 L 20.449219 -22.496094 C 20.046875 -21.996094 20.097656 -21.296875 20.496094 -20.847656 Z M 40.644531 1.25 C 43.09375 1.25 41.546875 -0.800781 41.546875 -0.800781 L 23.097656 -21.699219 L 38.996094 -40.394531 C 40.597656 -42.296875 38.046875 -42.496094 38.046875 -42.496094 L 26.648438 -42.496094 C 26.246094 -42.496094 25.898438 -42.296875 25.648438 -42.046875 L 13.75 -27.796875 L 13.75 -41.246094 C 13.75 -41.746094 13.25 -42.496094 12.5 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 12.5 1.25 C 13 1.25 13.75 0.800781 13.75 0 L 13.75 -14.148438 L 26.746094 0.851562 C 26.949219 1.101562 27.296875 1.25 27.695312 1.25 Z M 40.644531 1.25 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(535.519222, 58.999997)"><g>
                        <path className="path otherPath" d="M 25.449219 -39.996094 L 25.449219 -34.246094 L 12.5 -34.246094 C 11.699219 -34.246094 11.25 -33.496094 11.25 -32.996094 L 11.25 -24.796875 C 11.25 -23.996094 11.949219 -23.546875 12.5 -23.546875 L 24.847656 -23.546875 L 24.847656 -17.796875 L 12.5 -17.796875 C 11.699219 -17.796875 11.25 -17.046875 11.25 -16.546875 L 11.25 -8.25 C 11.25 -7.449219 11.949219 -7 12.5 -7 L 25.449219 -7 L 25.449219 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 Z M 27.945312 -41.246094 C 27.945312 -41.746094 27.445312 -42.496094 26.699219 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 26.699219 1.25 C 27.199219 1.25 27.945312 0.800781 27.945312 0 L 27.945312 -8.25 C 27.945312 -8.75 27.445312 -9.5 26.699219 -9.5 L 13.75 -9.5 L 13.75 -15.296875 L 26.097656 -15.296875 C 26.597656 -15.296875 27.347656 -15.75 27.347656 -16.546875 L 27.347656 -24.796875 C 27.347656 -25.296875 26.847656 -26.046875 26.097656 -26.046875 L 13.75 -26.046875 L 13.75 -31.746094 L 26.699219 -31.746094 C 27.199219 -31.746094 27.945312 -32.195312 27.945312 -32.996094 Z M 27.945312 -41.246094 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(567.964662, 58.999997)"><g>
                        <path className="path otherPath" d="M 11.25 -25.597656 L 11.25 -1.25 L 4.347656 -1.25 L 4.347656 -39.996094 L 11.796875 -39.996094 L 27.796875 -14.949219 C 28.046875 -14.597656 28.445312 -14.398438 28.898438 -14.398438 L 28.996094 -14.398438 C 29.746094 -14.398438 30.246094 -15.097656 30.246094 -15.648438 L 30.246094 -39.996094 L 37.144531 -39.996094 L 37.144531 -1.25 L 29.648438 -1.25 L 13.648438 -26.246094 C 13.398438 -26.597656 13 -26.847656 12.597656 -26.847656 L 12.5 -26.847656 C 11.699219 -26.847656 11.25 -26.097656 11.25 -25.597656 Z M 13.75 -21.449219 L 27.898438 0.699219 C 28.148438 1.050781 28.546875 1.25 28.996094 1.25 L 38.394531 1.25 C 38.894531 1.25 39.644531 0.800781 39.644531 0 L 39.644531 -41.246094 C 39.644531 -41.746094 39.144531 -42.496094 38.394531 -42.496094 L 28.996094 -42.496094 C 28.445312 -42.496094 27.746094 -41.996094 27.746094 -41.246094 L 27.746094 -19.746094 L 13.546875 -41.894531 C 13.296875 -42.246094 12.898438 -42.496094 12.5 -42.496094 L 3.101562 -42.496094 C 2.550781 -42.496094 1.851562 -41.996094 1.851562 -41.246094 L 1.851562 0 C 1.851562 0.550781 2.300781 1.25 3.101562 1.25 L 12.5 1.25 C 13 1.25 13.75 0.800781 13.75 0 Z M 13.75 -21.449219 " />
                    </g></g></g><g fill="#eab052" fill-opacity="1"><g transform="translate(612.558232, 58.999997)"><g>
                        <path className="path otherPath" d="M 19 -40.746094 C 24.246094 -40.746094 28.445312 -38.644531 30.148438 -37.644531 L 27.445312 -32.347656 C 25.847656 -33.246094 22.898438 -34.695312 19.648438 -34.695312 C 16.046875 -34.695312 13.5 -32.847656 13.5 -29.746094 C 13.5 -26.148438 18.5 -24.449219 22.847656 -22.597656 C 27.296875 -20.746094 31.746094 -17.097656 31.746094 -12.546875 C 31.746094 -3.601562 25.148438 -0.5 17.449219 -0.5 C 10.347656 -0.5 5.398438 -3.601562 3.550781 -4.949219 L 7 -10.699219 C 8.898438 -9.347656 12.847656 -6.699219 16.75 -6.699219 C 18.546875 -6.699219 23.949219 -6.800781 23.949219 -12 C 23.949219 -16.199219 18.347656 -17.699219 13.949219 -19.898438 C 9.25 -22.199219 6.800781 -25.699219 6.800781 -29.398438 C 6.800781 -36.246094 12.847656 -40.746094 19 -40.746094 Z M 19 -43.246094 C 11.796875 -43.246094 4.300781 -37.894531 4.300781 -29.398438 C 4.300781 -24.449219 7.597656 -20.246094 12.796875 -17.648438 C 18.199219 -14.949219 21.449219 -13.75 21.449219 -12 C 21.449219 -9.449219 19 -9.199219 16.75 -9.199219 C 12.796875 -9.199219 7.449219 -13.546875 7.449219 -13.546875 C 6.398438 -14.398438 5.75 -13.546875 5.550781 -13.199219 L 0.851562 -5.300781 C 0.550781 -4.847656 0.648438 -4.148438 1.050781 -3.75 C 1.199219 -3.648438 7.300781 2 17.449219 2 C 25.796875 2 34.246094 -1.75 34.246094 -12.546875 C 34.246094 -18.75 28.546875 -22.898438 23.796875 -24.949219 C 18.75 -27.097656 16 -28.695312 16 -29.746094 C 16 -31.097656 16.796875 -32.195312 19.648438 -32.195312 C 23.296875 -32.195312 27.246094 -29.546875 27.246094 -29.546875 C 28.195312 -28.898438 28.847656 -29.546875 29.097656 -29.996094 L 32.898438 -37.496094 C 33.148438 -37.996094 32.996094 -38.695312 32.496094 -39.097656 C 32.398438 -39.144531 26.898438 -43.246094 19 -43.246094 Z M 19 -43.246094 " /></g></g></g></svg>
        </>
    )
}
export default NameAMT